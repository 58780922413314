import { HttpService } from "../../services/http-service";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { LoaderUtil } from "../../utils/loaderUtils";

export default function CreateAccount() {

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            companyName: '',
            type: '',
            hissaId: ''
        },
        validationSchema: Yup.object({
            companyName: Yup.string().trim().required('Please enter company name'),
            hissaId: Yup.string().trim().required('Please enter hissa id'),
            type: Yup.string().trim().required('Please enter company type')
        }),
        onSubmit: values => {
            let body = {
                name: values.companyName,
                type: values.type,
                hissaId: values.hissaId
            };

            LoaderUtil.show();
            HttpService.post('account/company/create', body).then((result => {
                LoaderUtil.hide();
                navigate("/home/accounts");
            })).catch((e) => {

            });
        },
    });

    return (
        <>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Create Account</h1>
                <div className="mt-8 sm:w-full sm:max-w-md">
                    <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" onSubmit={formik.handleSubmit}>
                            <div>
                                <label htmlFor="companyName" className="block text-sm font-medium leading-6 text-gray-900">
                                    Company Name
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="companyName"
                                        type="text"
                                        className="block w-full rounded-md border-0 px-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        {...formik.getFieldProps('companyName')}
                                    />
                                    {formik.touched.companyName && formik.errors.companyName ?
                                        (
                                            <div className="error-msg">{formik.errors.companyName}</div>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div>
                                <label htmlFor="hissaId" className="block text-sm font-medium leading-6 text-gray-900">
                                    Hissa Id
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="hissaId"
                                        type="text"
                                        className="block w-full rounded-md border-0 px-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        {...formik.getFieldProps('hissaId')}
                                    />
                                    {formik.touched.hissaId && formik.errors.hissaId ?
                                        (
                                            <div className="error-msg">{formik.errors.hissaId}</div>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div>
                                <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">
                                    Type
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="type"
                                        type="text"
                                        className="block w-full rounded-md border-0 px-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        {...formik.getFieldProps('type')}
                                    />
                                    {formik.touched.type && formik.errors.type ?
                                        (
                                            <div className="error-msg">{formik.errors.type}</div>
                                        ) : null
                                    }
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Create
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
