import { useEffect, useState } from "react";
import { HttpService } from "../../services/http-service";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LoaderUtil } from "../../utils/loaderUtils";
import { DateUtil } from "../../utils/dateUtils";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef } from "ag-grid-community";
import ViewUsersCellRenderer from "./cellRenderer";
import PopupModal from "./viewUsersPopup";

const formatNumber = (value: { toLocaleString: () => any }) =>
  value.toLocaleString();

export default function CaptableStatus() {
  const [companies, setCompanies] = React.useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const navigate = useNavigate();
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const columns: ColDef[] = [
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      width: 350,
      filter: true,
    },
    {
      headerName: "On Platform Since",
      field: "onPlatformSince",
      valueFormatter: (params) =>
        DateUtil.formatDate(params.value, "DD MMM YY"),
      sortable: true,
      filter: true,
    },
    {
      headerName: "Is Private",
      field: "isPrivate",
      sortable: true,
      filter: true,
    },
    {
      headerName: "View Users",
      field: "userEmails",
      cellRenderer: ViewUsersCellRenderer,
      cellRendererParams: {
        setIsPopupOpen,
        setUserList,
      },
    },
    {
      headerName: "Shareholders",
      field: "noOfShareholders",
      valueFormatter: (params) => formatNumber(params.value),
      sortable: true,
      filter: true,
    },
    {
      headerName: "Rounds",
      field: "noOfRounds",
      valueFormatter: (params) => formatNumber(params.value),
      sortable: true,
      filter: true,
    },

    {
      headerName: "Captables",
      field: "noOfCaptables",
      valueFormatter: (params) => formatNumber(params.value),
      sortable: true,
      filter: true,
    },

    {
      headerName: "ID",
      field: "id",
      sortable: true,
      width: 350,
      filter: true,
    },
  ];
  useEffect(() => {
    const getCompanies = async () => {
      LoaderUtil.show();
      let response = await HttpService.get("captableCompanies/dbstats", true);
      LoaderUtil.hide();
      let fetchedAccounts = response.data.data;
      setCompanies(fetchedAccounts);
    };
    getCompanies();
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Companies On Prod- {companies.length}
          </h1>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div
              className="ag-theme-alpine"
              style={{ height: 600, width: 1050 }}
            >
              <AgGridReact
                rowData={companies}
                columnDefs={columns}
                pagination={true}
                paginationPageSize={15}
                enableCellTextSelection={true}
                ensureDomOrder={true}
              />
            </div>
          </div>
        </div>
        {isPopupOpen && (
          <PopupModal isPopupOpen userList={userList} onClose={closePopup} />
        )}
      </div>
    </div>
  );
}
