import { useRoutes } from "react-router-dom";
import Login from "../pages/login/login";
import Home from "../pages/home/home";
import Accounts from "../pages/accounts/accounts";
import Users from "../pages/users/users";
import CreateAccount from "../pages/accounts/create-account";
import CaptableStatus from "../pages/captableStatus/captable-status";

export default function Router() {
  return useRoutes([
    {
      path: "",
      element: <Login />,
    },
    {
      path: "/home",
      element: <Home />,
      children: [
        {
          path: "accounts",
          element: <Accounts />,
        },
        {
          path: "create-account",
          element: <CreateAccount />,
        },
        {
          path: "users/:accountId",
          element: <Users />,
        },
        {
          path: "captable-status",
          element: <CaptableStatus />,
        },
      ],
    },
  ]);
}
