import { loaderAuthStore } from "../store/loaderStore";

export class LoaderUtil {
    public static show(): void {
        loaderAuthStore.setState({isLoading: true});
        document.body.style.overflow = 'hidden';
    }

    public static hide(): void {
        loaderAuthStore.setState({isLoading: false});
        document.body.style.overflow = 'unset';
    }
}