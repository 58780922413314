import './App.scss';
import Loader from './components/loader/loader';
import Router from './routes/router';
import { userAuthStore } from './store';

function App() {
  userAuthStore();
  return (
    <>
    <Loader />
    <Router />
    </>
  );
}

export default App;
