import axios, { AxiosResponse } from "axios";

export class HttpService {
    
    private static API_URL = "";

    private static getHeaders() {
        let user:any = localStorage.getItem("user");
        if(user != null) {
            user = JSON.parse(user);
            return {
                Authorization: user ? `Bearer ${user.token}` : undefined,
            }
        }
        
        else {
            return undefined;
        }
    }

    public static async post(url: any, body: any, headers = true): Promise<AxiosResponse<any, any>> {
        HttpService.API_URL = process.env.REACT_APP_API_URL?.toString() || "http://localhost:4001";
        return axios.create({
            baseURL: HttpService.API_URL,
        }).post(url, body, {headers: headers ? HttpService.getHeaders() : undefined})
        
    }

    // delete is a reserved name
    public static async del(url: string, headers = true) : Promise<AxiosResponse<any, any>> {
        HttpService.API_URL = process.env.REACT_APP_API_URL?.toString() || "http://localhost:4001";
        return axios.create({
            baseURL: HttpService.API_URL,
        }).delete(url, {headers: headers ? HttpService.getHeaders() : undefined});
    }

    public static async get(url: string, headers = true) : Promise<AxiosResponse<any, any>> {
        HttpService.API_URL = process.env.REACT_APP_API_URL?.toString() || "http://localhost:4001";
        return axios.create({
            baseURL: HttpService.API_URL
        }).get(url, {headers: headers ? HttpService.getHeaders() : undefined})
    }

    public static async patch(url: string, body: any, headers = true) : Promise<AxiosResponse<any, any>> {
        HttpService.API_URL = process.env.REACT_APP_API_URL?.toString() || "http://localhost:4001";
        return axios.create({
            baseURL: HttpService.API_URL,
        }).patch(url, body, {headers: headers ? HttpService.getHeaders() : undefined})
    }
}
