import { create } from "zustand";

interface ILoader {
    isLoading:boolean;
}
interface ILoaderAuthStore extends ILoader {
    show(): void;
    hide():void;
}

export const loaderAuthStore = create<ILoaderAuthStore>(
    (set: any) => ({
        isLoading: false,
        show: () => {
            set((_state: { isLoading: boolean; }) => true)
        },
        hide: () => {
            set((_state: { isLoading: boolean; }) => false)
        }
    })
)