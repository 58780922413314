import { useEffect } from "react"
import { HttpService } from "../../services/http-service";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoaderUtil } from "../../utils/loaderUtils";

  
  export default function Accounts() {

    const [accounts, setAccounts] = React.useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const getAccounts = async () => {
            LoaderUtil.show();
            let response = await HttpService.get('account/all', true);
            LoaderUtil.hide();
            let fetchedAccounts = response.data.data;
            setAccounts(fetchedAccounts);            
        }
        getAccounts();
    }, []);

    function gotoCreateAccount() {
      navigate("/home/create-account");
    }

    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Accounts</h1>
            {/* <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their name, title, email and role.
            </p> */}
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={gotoCreateAccount}>
              Add account
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Type
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Hissa Identifier
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Users
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {accounts.map((account:any) => (
                    <tr key={account.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {account.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{account.type}</td>
                        <td className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500`}>
                            <span className={`${account.state === 'Active' ? 'inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800' : ''}`}>
                            {account.state}
                            </span>
                        </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{account.hissaId}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <Link to={`/home/users/${account.id}`}> View Users </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
  