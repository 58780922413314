/* eslint-disable */
import { create } from "zustand";
import { configurePersist } from "zustand-persist";
import { UserModel } from "../models/userModel";


interface IAuth {
  isAuthenticated?: boolean;
  user?: UserModel;
}
interface IAuthStore extends IAuth {
  setAuth: (auth: IAuth | ((auth: IAuth) => IAuth)) => void;
  clear: () => void;
}

export const userAuthStore = create<IAuthStore>(
    (set: any) => ({
        isAuthenticated: false,
        user: undefined,
        setAuth: (auth: IAuth | ((auth: IAuth) => IAuth)) => {
          if (typeof auth === "function") {
            set((_state: IAuth) => auth(_state));
          } else {
            set((_state: IAuth) => auth);
          }
        },
        clear: () => {
          set((_state: any) => ({
            isAuthenticated: false,
            user: undefined,
          }));
        },
      })
);